import Cookies from "js-cookie";
import {COOKIE} from "../constants/api";

export function initTheme() {
    if (isDark()) {
        document.documentElement.classList.add('dark')
        document.body.classList.add('dark:bg-gray-900')
    } else {
        document.documentElement.classList.remove('dark')
        document.body.classList.remove('dark:bg-gray-900')
    }
}

export function setTheme(theme: string) {
    localStorage.theme = theme
    initTheme()
}

export function getTheme(): string | undefined {
    return localStorage.theme
}

export function removeTheme() {
    localStorage.removeItem('theme')
}

export function isDark(): boolean {
    const apiToken = Cookies.get(COOKIE.API_TOKEN)
    if (apiToken === undefined || apiToken?.length === 0)
        return false
    if (window.location.href.indexOf('/signup') > 0 || window.location.href.indexOf('/login') > 0)
        return false
    return localStorage.theme === 'DARK'
        || (localStorage.theme === 'AUTO' && window.matchMedia('(prefers-color-scheme: dark)').matches)
        || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
}