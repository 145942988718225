const MAIN = '/'
const NOTIFICATIONS = '/notifications'
const LOGIN = '/login'
const SIGNUP = '/signup'
const LOGOUT = '/logout'
const PASSWORD_RECOVERY = '/password-recovery'

const HISTORY = '/history'

const SETTINGS = '/settings'
const SETTINGS_STUDY = `${SETTINGS}/study`
const SETTINGS_STUDY_BATCH_SIZE = `${SETTINGS_STUDY}/study-batch-size`
const SETTINGS_STUDY_REPEAT_STAGES = `${SETTINGS_STUDY}/study-repeat-stages`
const SETTINGS_STUDY_WEEKLY_TARGET = `${SETTINGS_STUDY}/study-weekly-target`
const SETTINGS_STUDY_AUTO_PLAY = `${SETTINGS_STUDY}/study-auto-play`
const SETTINGS_NOTIFICATIONS = `${SETTINGS}/notifications`
const SETTINGS_LANGUAGE = `${SETTINGS}/language`
const SETTINGS_LANGUAGE_FROM_STUDY = `${SETTINGS}/language/study`
const SETTINGS_LANGUAGE_FROM_ACCOUNT = `${SETTINGS}/language/account`
const SETTINGS_LANGUAGE_DISPLAY = `${SETTINGS_LANGUAGE}/display`
const SETTINGS_LANGUAGE_NATIVE = `${SETTINGS_LANGUAGE}/native`
const SETTINGS_LANGUAGE_AUTO_TRANSLATE = `${SETTINGS_LANGUAGE}/auto-translation`
const SETTINGS_THEME = `${SETTINGS}/theme`
const SETTINGS_ACCOUNT = `${SETTINGS}/account`
const SETTINGS_ACCOUNT_NAME = `${SETTINGS_ACCOUNT}/name`
const SETTINGS_ACCOUNT_EMAIL = `${SETTINGS_ACCOUNT}/email`
const SETTINGS_ACCOUNT_PASSWORD = `${SETTINGS_ACCOUNT}/password`
const SETTINGS_ACCOUNT_LANGUAGE = `${SETTINGS_ACCOUNT}/language`

const SEARCH = '/search'

export const WORD = '/word'
const WORD_BY_ID = `${WORD}/:wordId`
const WORD_USAGE_EXAMPLES = `${WORD}/:wordId/usage-examples`

const STUDY = '/study'
const STUDY_SELECT_TOPIC = `${STUDY}/by-topic`
const STUDY_BY_LEVEL = `${STUDY}/by-level`
const STUDY_BY_TOPIC = `${STUDY}/by-topic/:topicId`
const STUDY_BY_OX5K = `${STUDY}/by-ox5k`
const STUDY_USER_WORDS_LIST = `${STUDY}/custom`

const MY_VOCABULARY = '/my-vocabulary'
const MY_VOCABULARY_THIS_MONTH = `${MY_VOCABULARY}/this-month`

const PRACTICE = '/practice'
const PRACTICE_SELECT_WORDS = '/practice/select/:type/:value'
const PRACTICE_REPEAT_SELECT_WORDS = '/practice/select/:repeat'
const PRACTICE_DEFINITION = `${PRACTICE}/definition`
const PRACTICE_DEFINITION2 = `${PRACTICE}/definition2`
const PRACTICE_VIDEO_USAGE_EXAMPLES = `${PRACTICE}/video-usage-examples`
const PRACTICE_LISTENING = `${PRACTICE}/listening`
const PRACTICE_TYPE_MISSING_LETTERS = `${PRACTICE}/type-missing-letters`
const PRACTICE_COLLECT_WORD = `${PRACTICE}/collect-word`
const PRACTICE_SUMMARY = `${PRACTICE}/summary`

export const ROUTES = {
    ANY: '/*',
    MAIN,
    PRACTICE: {
        ANY: `${PRACTICE}/*`,
        MAIN: PRACTICE_SELECT_WORDS,
        REPEAT: PRACTICE_REPEAT_SELECT_WORDS,
        DEFINITION: PRACTICE_DEFINITION,
        DEFINITION2: PRACTICE_DEFINITION2,
        VIDEO_USAGE_EXAMPLES: PRACTICE_VIDEO_USAGE_EXAMPLES,
        LISTENING: PRACTICE_LISTENING,
        TYPE_MISSING_LETTERS: PRACTICE_TYPE_MISSING_LETTERS,
        COLLECT_WORD: PRACTICE_COLLECT_WORD,
        SUMMARY: PRACTICE_SUMMARY,
    },
    HISTORY: {
        MAIN: HISTORY
    },
    STUDY: {
        ANY: `${STUDY}/*`,
        MAIN: STUDY,
        SELECT_TOPIC: STUDY_SELECT_TOPIC,
        BY_OX5K: STUDY_BY_OX5K,
        BY_LEVEL: STUDY_BY_LEVEL,
        BY_TOPIC: STUDY_BY_TOPIC,
        CUSTOM_LIST: STUDY_USER_WORDS_LIST
    },
    WORD: {
        ANY: `${WORD}/*`,
        MAIN: WORD_BY_ID,
        USAGE_EXAMPLES: WORD_USAGE_EXAMPLES,
    },
    SEARCH: {
        ANY: `${SEARCH}/*`,
        MAIN: SEARCH,
    },
    POLICY: {
        ANY: '/policy/*',
        TERMS: '/policy/terms',
        PRIVACY: '/policy/privacy',
    },
    SUPPORT: {
        ANY: '/support/*',
        MAIN: '/support'
    },
    ONBOARDING: {
        ANY: '/onboarding/*',
        MAIN: '/onboarding'
    },
    MY_VOCABULARY: {
        ANY: `${MY_VOCABULARY}/*`,
        MAIN: MY_VOCABULARY,
        THIS_MONTH: MY_VOCABULARY_THIS_MONTH
    },
    SETTINGS: {
        ANY: `${SETTINGS}/*`,
        MAIN: SETTINGS,
        NOTIFICATIONS: SETTINGS_NOTIFICATIONS,
        LANGUAGE: {
            ANY: `${SETTINGS_LANGUAGE}/*`,
            MAIN: SETTINGS_LANGUAGE,
            MAIN_FROM_STUDY: SETTINGS_LANGUAGE_FROM_STUDY,
            MAIN_FROM_ACCOUNT: SETTINGS_LANGUAGE_FROM_ACCOUNT,
            DISPLAY_LANGUAGE: SETTINGS_LANGUAGE_DISPLAY,
            NATIVE_LANGUAGE: SETTINGS_LANGUAGE_NATIVE,
            AUTO_TRANSLATE: SETTINGS_LANGUAGE_AUTO_TRANSLATE,
        },
        THEME: SETTINGS_THEME,
        ACCOUNT: {
            ANY: `${SETTINGS_ACCOUNT}/*`,
            MAIN: SETTINGS_ACCOUNT,
            NAME: SETTINGS_ACCOUNT_NAME,
            EMAIL: SETTINGS_ACCOUNT_EMAIL,
            PASSWORD: SETTINGS_ACCOUNT_PASSWORD,
            LANGUAGE: SETTINGS_ACCOUNT_LANGUAGE
        },
        STUDY: {
            ANY: `${SETTINGS_STUDY}/*`,
            MAIN: SETTINGS_STUDY,
            BATCH_SIZE: SETTINGS_STUDY_BATCH_SIZE,
            REPEAT_STAGES: SETTINGS_STUDY_REPEAT_STAGES,
            WEEKLY_TARGET: SETTINGS_STUDY_WEEKLY_TARGET,
            AUTO_PLAY: SETTINGS_STUDY_AUTO_PLAY,
        }
    },
    NOTIFICATIONS,
    LOGOUT,
    LOGIN: {
        ANY: `${LOGIN}/*`,
        MAIN: LOGIN,
        REDIRECT: `${LOGIN}/redirect`,
    },
    SIGNUP: {
        ANY: `${SIGNUP}/*`,
        MAIN: SIGNUP,
        REDIRECT: `${SIGNUP}/redirect`,
    },
    PASSWORD_RECOVERY: {
        ANY: `${PASSWORD_RECOVERY}/*`,
        MAIN: PASSWORD_RECOVERY,
        SECURITY_CODE: `${PASSWORD_RECOVERY}/code`,
        NEW_PASSWORD: `${PASSWORD_RECOVERY}/new-password`,
    },
}