import {api} from './index'
import {RecentlyAndEarlierNotifications} from "../interfaces/notifications";

export const getNotifications = (page: number) =>
    api.get<RecentlyAndEarlierNotifications>(`/notifications?page=${page}`)

export const getNewNotificationsCount = () =>
    api.get<number>(`/notifications/new`)

export const touchDeviceToken = (token: string) =>
    api.post<any>(`/notifications/token`, token)
