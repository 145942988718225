import axios, {AxiosError, AxiosPromise, AxiosRequestConfig} from 'axios'
import get from 'lodash/get'

import {API_ENDPOINT, COOKIE} from '../constants/api'
// import { pushError } from '../store/notifications/actions'
import {ApiVersion} from '../interfaces'
import Cookies from 'js-cookie'

export const handleError = (error: AxiosError) => {
  // pushError({ error })
  throw error
}

// Onboarding user can't make requests to non onboarding endpoints
// This is used to avoid error notifications when the onboarding user hits the root URL
export const onboardingHandleError = (error: AxiosError) => {
  if (
    get(error, 'response.status') === 403 &&
    get(error, 'response.data.detail') ===
      'You do not have permission to perform this action.'
  ) {
    throw error
  } else {
    return handleError(error)
  }
}

export const apiWithoutHandling = {
  get: <T>(route: string, config?: AxiosRequestConfig, apiVersion: ApiVersion = 'v1') => {
    return axios.get<T>(`${API_ENDPOINT()}${apiVersion}${route}`, {
      headers: {
        'X-XSRF-TOKEN': Cookies.get(COOKIE.API_TOKEN) || '',
      },
      ...config,
    })
  },
  post: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
  ): AxiosPromise<T> => {
    return axios.post<T>(`${API_ENDPOINT()}${apiVersion}${route}`, data, {
      headers: {
        'X-XSRF-TOKEN': Cookies.get(COOKIE.API_TOKEN) || '',
      },
      ...config,
    })
  },
  patch: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
  ): AxiosPromise<T> => {
    return axios.patch<T>(`${API_ENDPOINT()}${apiVersion}${route}`, data, {
      headers: {
        'X-XSRF-TOKEN': Cookies.get(COOKIE.API_TOKEN) || '',
      },
      ...config,
    })
  },
  put: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
  ): AxiosPromise<T> => {
    return axios.put<T>(`${API_ENDPOINT()}${apiVersion}${route}`, data, {
      headers: {
        'X-XSRF-TOKEN': Cookies.get(COOKIE.API_TOKEN) || '',
      },
      ...config,
    })
  },
  delete: <T = any>(
    route: string,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
  ): AxiosPromise => {
    return axios.delete<T>(`${API_ENDPOINT()}${apiVersion}${route}`, {
      headers: {
        'X-XSRF-TOKEN': Cookies.get(COOKIE.API_TOKEN) || '',
      },
      ...config,
    })
  },
}

export const api = {
  get: <T>(route: string, config?: AxiosRequestConfig, apiVersion: ApiVersion = 'v1') => {
    return axios
      .get<T>(`${API_ENDPOINT()}${apiVersion}${route}`, {
        headers: {
          'X-XSRF-TOKEN': Cookies.get(COOKIE.API_TOKEN) || '',
        },
        ...config,
      })
      .catch(handleError)
  },
  post: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
  ): AxiosPromise<T> => {
    return axios
      .post<T>(`${API_ENDPOINT()}${apiVersion}${route}`, data, {
        headers: {
          'X-XSRF-TOKEN': Cookies.get(COOKIE.API_TOKEN) || '',
        },
        ...config,
      })
      .catch(handleError)
  },
  patch: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
  ): AxiosPromise<T> => {
    return axios
      .patch<T>(`${API_ENDPOINT()}${apiVersion}${route}`, data, {
        headers: {
          'X-XSRF-TOKEN': Cookies.get(COOKIE.API_TOKEN) || '',
        },
        ...config,
      })
      .catch(handleError)
  },
  put: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
  ): AxiosPromise<T> => {
    return axios
      .put<T>(`${API_ENDPOINT()}${apiVersion}${route}`, data, {
        headers: {
          'X-XSRF-TOKEN': Cookies.get(COOKIE.API_TOKEN) || '',
        },
        ...config,
      })
      .catch(handleError)
  },
  delete: <T = any>(
    route: string,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
  ): AxiosPromise<T> => {
    return axios
      .delete<T>(`${API_ENDPOINT()}${apiVersion}${route}`, {
        headers: {
          'X-XSRF-TOKEN': Cookies.get(COOKIE.API_TOKEN) || '',
        },
        ...config,
      })
      .catch(handleError)
  },
}
