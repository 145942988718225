import {UserInterface} from "../../interfaces/user";

export enum AuthActionTypes {
    SET_API_TOKEN = '@auth/SET_API_TOKEN',
    TEST_LOGIN = '@auth/TEST_LOGIN',
    SET_LOGIN_URL = '@auth/SET_LOGIN_URL',
    GET_LOGIN_URL = '@auth/GET_LOGIN_URL',
    VERIFY_API_TOKEN = '@auth/VERIFY_API_TOKEN',
    LOG_OUT = '@auth/LOG_OUT',
    LOG_IN = '@auth/LOG_IN',
    SET_AUTHENTICATED = '@auth/SET_AUTHENTICATED',
    SET_USER = '@auth/SET_USER',
}

export interface AuthState {
    readonly authenticated: boolean
    readonly user: UserInterface
    readonly loginUrl?: string
}