export enum Environments {
  local = 'local',
  same_network = '192.168.1.66',
  prod = 'multilio.com'
}

export const COOKIE = {
  API_TOKEN: 'api_token',
  AUTHENTICATED: 'authenticated',
  PERFORMANCE_INFO_SEEN: 'performance_info_seen',
  DELIVERABLES_INFO_SEEN: 'deliverables_info_seen',
  UPWARDS_INFO_SEEN: 'upwards_info_seen',
  EMAIL: 'email',
  KPI_IMPORTANT_INFO_SEEN: 'kpi_important_info_seen',
  ORIGINAL_TOKEN: 'original_api_token',
  PERMISSIONS: 'permissions',
  USER: 'user',
  PERFORMANCE_REJECT_DISMISSED: 'performance_reject_dismissed',
}

export const Hosts = {
  [Environments.local]: 'localhost:3000',
  [Environments.prod]: 'multilio.com:7443',
  [Environments.same_network]: `${Environments.same_network}:7001`,
}

const hostnameToEnv = {
  localhost: Environments.local,
  'multilio.com': Environments.prod,
  '192.168.1.66': Environments.same_network
}
// @ts-ignore
export const env = hostnameToEnv[window.location.hostname]

export const APIMap = {
  [Environments.local]: 'http://localhost:7001/api/',
  [Environments.same_network]: `http://${Environments.same_network}:7001/api/`,
  [Environments.prod]: 'https://multilio.com:7443/api/',
}

export const cdnHost = (): string => {
    if (env === Environments.prod)
      return 'https://d2i3qyi0m4zc0k.cloudfront.net/build'
    return ''
}

export const API_ENDPOINT = () =>
  // @ts-ignore
  localStorage.getItem('api_endpoint') || window.__API_URL__ || APIMap[env]

export const WSMap = {
  [Environments.local]: 'wss://localhost/ws/v1/user/',
}

export const WSS_ENDPOINT = () =>
  // @ts-ignore
  localStorage.getItem('wss_endpoint') || window.__WSS_URL__ || WSMap[env]

export const REDIRECT_PARAM = `${document.location.origin}/login/redirect`
export const API = {
  ACCOUNT: '/account',
}

export const LOCAL_STORAGE = {
  FEEDBACK_DATE: 'feedbackDate',
  SHOW_CANDIDATES_ASSIGNED_TO_ME: 'showCandidatesAssignedToMe',
  EMPLOYEE_PROFILE_TAB_ORDER: 'emloyeeProfileTabOrder',
  SHOW_PREVIOUS_RATINGS: 'showPreviousPerformanceRatings',
}
