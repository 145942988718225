import { createBrowserHistory } from 'history'
import { compile, pathToRegexp, Key } from 'path-to-regexp'

// There is no DOM in jest node environment, only for jest testing
// eslint-disable-next-line import/no-mutable-exports
export let history = typeof window === 'undefined' ? ({} as any) : createBrowserHistory()

export const pathToUrl = (path: string, params: object = {}) => compile(path)(params)

export const pathToParams = (path: string) => {
  const keys = [] as Key[]
  const regexp = pathToRegexp(path, keys)
  const match = regexp.exec(window.location.pathname)
  if (!match) {
    return {}
  }
  const [, ...values] = match
  return keys.reduce((acc, key, index) => {
    acc[key.name] = values[index]
    return acc
  }, {} as { [key: string]: string })
}

export const newTabTo = (path: string, params: object = {}) => {
  if (window) {
    const tab = window.open(pathToUrl(path, params), '_blank')
    tab?.focus()
  }
}
