import {SenseInfo, Word} from "../../interfaces/practice";
import {AutoPlayInterface, TranslationInterface} from "../../interfaces/settings";

export enum PracticeActionTypes {
    PRACTICE_RESET = '@practice/PRACTICE_RESET',

    SET_PRACTICE_WORDS = '@practice/SET_PRACTICE_WORDS',
    GET_PRACTICE_WORDS = '@practice/GET_PRACTICE_WORDS',

    SET_PRACTICE_TRANSLATION = '@practice/SET_PRACTICE_TRANSLATION',

    SET_PRACTICE_EXTRAS_WORDS = '@practice/SET_PRACTICE_EXTRAS_WORDS',
    SET_PRACTICE_AUTOPLAY = '@practice/SET_PRACTICE_AUTOPLAY',

    SET_CURRENT_PRACTICE_WORD_INDEX = '@practice/SET_CURRENT_PRACTICE_WORD_INDEX',
    GET_CURRENT_PRACTICE_WORD_INDEX = '@practice/GET_CURRENT_PRACTICE_WORD_INDEX',

    SET_PRACTICE_STEPS_COUNT = '@practice/SET_PRACTICE_STEPS_COUNT',
    SET_PRACTICE_STEP_INDEX = '@practice/GET_PRACTICE_STEP_INDEX',
    SET_PRACTICE_STARTED_AT = '@practice/SET_PRACTICE_STARTED_AT',
    SET_PRACTICE_FINISHED_AT = '@practice/SET_PRACTICE_FINISHED_AT',
    SET_PRACTICE_STUDY_TYPE = '@practice/SET_PRACTICE_STUDY_TYPE',
    SET_PRACTICE_SKIPPED_SENSES = '@practice/SET_PRACTICE_SKIPPED_SENSES',
    SET_PRACTICE_SKIPPED_WORDS = '@practice/SET_PRACTICE_SKIPPED_WORDS',
    SET_PRACTICE_SENSES_TO_STUDY = '@practice/SET_PRACTICE_SENSES_TO_STUDY',

    ADD_PRACTICE_DEFINITION_STATISTIC = '@practice/ADD_PRACTICE_DEFINITION_STATISTIC',
    ADD_PRACTICE_SELECT_DEFINITION_STATISTIC = '@practice/ADD_PRACTICE_SELECT_DEFINITION_STATISTIC',
    ADD_PRACTICE_LISTENING_STATISTIC = '@practice/ADD_PRACTICE_LISTENING_STATISTIC',
    ADD_PRACTICE_MISTING_LETTER_STATISTIC = '@practice/ADD_PRACTICE_MISTING_LETTER_STATISTIC',
    ADD_PRACTICE_COLLECT_WORD_STATISTIC = '@practice/ADD_PRACTICE_COLLECT_WORD_STATISTIC',

    SET_PRACTICE = '@practice/SET_PRACTICE',
}

export interface PracticeDefinitionStatistic {
    wordId: string,
    timeSpent: number
}

export interface PracticeSelectDefinitionStatistic {
    wordId: string,
    definition: number,
    timeSpent: number,
    correct: boolean
}

export interface PracticeListeningStatistic {
    word: string,
    timeSpent: number,
    correct: boolean
}

export interface PracticeMissingLettersStatistic {
    wordId: string,
    definition: string,
    timeSpent: number,
    correct: boolean,
    position: number,
    letterTyped?: string
}

export interface PracticeCollectWordStatistic {
    wordId: string,
    definition: string,
    timeSpent: number,
    correct: boolean,
    position: number,
    letterTyped?: string
}

export interface StudyType {
    repeat: boolean
    type?: string
    customWordsList: boolean
    value?: string
}

export interface PracticeState {
    words: Word[]
    extrasWords: Word[]
    wordIndex: number
    stepsCount: number
    stepIndex: number
    startedAt: number
    finishedAt: number
    practiceDefinitionStatistic: PracticeDefinitionStatistic[]
    practiceSelectDefinitionStatistic: PracticeSelectDefinitionStatistic[]
    practiceListeningStatistic: PracticeListeningStatistic[]
    practiceMissingLettersStatistic: PracticeMissingLettersStatistic[]
    practiceCollectWordStatistic: PracticeCollectWordStatistic[]
    autoplay?: AutoPlayInterface
    translation: TranslationInterface
    studyType?: StudyType
    skippedWords: string[]
    skippedSenses: SenseInfo[]
    sensesToStudy: SenseInfo[]
}