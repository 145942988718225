import {Reducer} from 'redux'
import {produce} from 'immer'
import Cookies from 'js-cookie'
import {AuthActionTypes, AuthState} from './types'
import {COOKIE} from '../../constants/api'

const initialAuthState: AuthState = {
    authenticated: !!Cookies.get(COOKIE.API_TOKEN),
    user: JSON.parse(Cookies.get(COOKIE.USER) || '{}'),
    loginUrl: undefined,
}

const reducer: Reducer<AuthState> = (state = initialAuthState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case AuthActionTypes.SET_AUTHENTICATED:
                draft.authenticated = action.payload
                break
            case AuthActionTypes.SET_USER:
                draft.user = action.payload
                break
            case AuthActionTypes.SET_LOGIN_URL:
                draft.loginUrl = action.payload
                break
            default:
                break
        }
    })
}

export { reducer as AuthReducer }
