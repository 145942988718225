import {api} from './index'
import {
    AutoPlayInterface,
    NotificationsInterface,
    SettingsPractice,
    SettingsInterface, SettingsLanguageInterface,
    StudyBatchSizeInterface,
    StudyRepeatStagesInterface, TranslationInterface,
    WeeklyTargetInterface
} from "../interfaces/settings"

export const settingsAll = () =>
    api.get<SettingsInterface>(`/settings`)

export const settingsLanguage = () =>
    api.get<SettingsLanguageInterface>(`/settings/language`)

export const settingsPracticeRelated = () =>
    api.get<SettingsPractice>(`/settings/practice-related`)

export const updateNotifications = (data: NotificationsInterface) =>
    api.post<string>(`/settings/notifications`, data)

export const updateAutoPlay = (data: AutoPlayInterface) =>
    api.post<string>(`/settings/autoplay`, data)

export const updateWeeklyTarget = (data: WeeklyTargetInterface) =>
    api.post<string>(`/settings/weekly-target`, data)

export const updateRepeatStages = (data: StudyRepeatStagesInterface) =>
    api.post<string>(`/settings/repeat-stages`, data)

export const updateStudyBatchSize = (data: StudyBatchSizeInterface) =>
    api.post<string>(`/settings/study-batch-size`, data)

export const updateTranslation = (data: TranslationInterface) =>
    api.post<string>(`/settings/translation`, data)