import * as React from 'react'
import {useSelector} from "react-redux"
import {selectAuthenticated} from "../../store/auth/selectors"
import Landing3 from "./Landing3";
import Home from "./Home";
// import Home from "./Home";

// const Landing = React.lazy(() => import('./Landing3'))
// const Home = React.lazy(() => import('./Home'))

const HomeRouter = () => {
    // const isLoggedIn = useSelector(selectAuthenticated)
    // if (!isLoggedIn) {
        return <Landing3 />
    // }
    // return <Home />
}

export default HomeRouter
