import React, {Suspense} from 'react'
import {Route, Switch} from 'react-router-dom'
import {ROUTES} from "../constants/routes"
import InitializingLoader from "../components/Loaders/InitializingLoader"
import UnauthorizedRoute from "../components/Routes/UnauthorizedRoute"
import StudyRouter from "./Study/Router"
import PrivateRoute from "../components/Routes/PrivateRoute"
import HomeRouter from "./Home/Router"
import SettingsRouter from "./Settings/Router"
import Notifications from "./Notifications/Notifications"
import PracticeRouter from "./Practice/Router"
// import Logout from "./Logout/Logout"
import AuthRouter from "./Auth/Router"
import MyVocabularyRouter from "./MyVocabulary/Router"
// import PolicyRouter from "./Policy/Router"
// import NotFound from "./NotFound/NotFound"
import SearchRouter from "./Search/Router";
import WordRouter from "./Word/Router";
import OnboardingRouter from "./Onboarding/Router";

// const UnauthorizedRoute = React.lazy(() => import('../components/Routes/UnauthorizedRoute'))
// const PrivateRoute = React.lazy(() => import('../components/Routes/PrivateRoute'))
// const OnboardingRouter = React.lazy(() => import('./Onboarding/Router'))
// const WordRouter = React.lazy(() => import('./Word/Router'))
const NotFound = React.lazy(() => import('./NotFound/NotFound'))
// const SearchRouter = React.lazy(() => import('./Search/Router'))
// const StudyRouter = React.lazy(() => import('./Study/Router'))
// const HomeRouter = React.lazy(() => import('./Home/Router'))
// const SettingsRouter = React.lazy(() => import('./Settings/Router'))
// const Notifications = React.lazy(() => import('./Notifications/Notifications'))
// const PracticeRouter = React.lazy(() => import('./Practice/Router'))
const Logout = React.lazy(() => import('./Logout/Logout'))
// const AuthRouter = React.lazy(() => import('./Auth/Router'))
// const MyVocabularyRouter = React.lazy(() => import('./MyVocabulary/Router'))
const PolicyRouter = React.lazy(() => import('./Policy/Router'))
const SupportRouter = React.lazy(() => import('./Support/Router'))

const App = () => {

  return (
    <>
        <Suspense fallback={<InitializingLoader />}>
            <Switch>
              <Route exact path={ROUTES.MAIN} component={HomeRouter} />
              {/*<PrivateRoute exact path={[ROUTES.MY_VOCABULARY.MAIN, ROUTES.MY_VOCABULARY.ANY]} component={MyVocabularyRouter} />*/}
              {/*<PrivateRoute exact path={[ROUTES.SEARCH.MAIN, ROUTES.SEARCH.ANY]} component={SearchRouter} />*/}
              {/*<PrivateRoute exact path={[ROUTES.WORD.MAIN, ROUTES.WORD.ANY]} component={WordRouter} />*/}
              {/*<PrivateRoute exact path={[ROUTES.STUDY.MAIN, ROUTES.STUDY.ANY]} component={StudyRouter} />*/}
              {/*<PrivateRoute exact path={[ROUTES.SETTINGS.MAIN, ROUTES.SETTINGS.ANY]} component={SettingsRouter} />*/}
              {/*<PrivateRoute exact path={ROUTES.NOTIFICATIONS} component={Notifications} />*/}
              {/*<PrivateRoute exact path={ROUTES.PRACTICE.ANY} component={PracticeRouter} />*/}
              {/*<PrivateRoute exact path={ROUTES.HISTORY.MAIN} component={History} />*/}
              {/*<PrivateRoute exact path={ROUTES.ONBOARDING.MAIN} component={OnboardingRouter} />*/}
              {/*<PrivateRoute exact path={ROUTES.LOGOUT} component={Logout} />*/}
              <Route exact path={ROUTES.POLICY.ANY} component={PolicyRouter} />
              <Route exact path={ROUTES.SUPPORT.MAIN} component={SupportRouter} />
              {/*<UnauthorizedRoute exact*/}
              {/*       path={[*/}
              {/*           ROUTES.LOGIN.MAIN,*/}
              {/*           ROUTES.SIGNUP.MAIN,*/}
              {/*           ROUTES.PASSWORD_RECOVERY.MAIN,*/}
              {/*           ROUTES.PASSWORD_RECOVERY.ANY*/}
              {/*       ]}*/}
              {/*       component={AuthRouter} />*/}
              {/*<Route path={"*"} component={NotFound}/>*/}
            </Switch>
        </Suspense>
    </>
  );
}

export default App
