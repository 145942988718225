import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getMessaging, getToken} from "firebase/messaging";
import {touchDeviceToken} from "../api/notifications";
import Cookies from "js-cookie";
import {COOKIE} from "../constants/api";

const firebaseConfig = {
    apiKey: "AIzaSyDxB4OcoopvW0Io_Uj23zL0MdJoQF6JVlo",
    authDomain: "monochase-language-learning.firebaseapp.com",
    projectId: "monochase-language-learning",
    storageBucket: "monochase-language-learning.appspot.com",
    messagingSenderId: "866056413081",
    appId: "1:866056413081:web:5402051e661b8e09537d08",
    measurementId: "G-1EY0BCCJR7"
}

export type enableNotificationCallback = (enabled: boolean) => void;

export function enableNotifications(callback: enableNotificationCallback) {
    if (Notification.permission !== "granted") {
        Notification.requestPermission().then(function(permission) {
            const granted = permission === 'granted'
            if (granted)
                fetchToken()
            callback(granted)
        })
    } else {
        callback(true)
    }
}

export function initCloudMessage() {
    try {
        if (Notification.permission === "granted") {
            fetchToken()
        }
    } catch (ignore) {}
}

export function fetchToken() {
    const app = initializeApp(firebaseConfig)
    const analytics = getAnalytics(app)

    const messaging = getMessaging()

    getToken(messaging, { vapidKey: "BMeP2ACZT40CGH2HzdLFm4gT8kH9hRYQ1OF_UVWV7-1MGdxzkYQ6iZQWmUYbU8x1zcQ-cGhrVlsrLTqncj6IEFg" }).then((currentToken) => {
        if (currentToken) {
            let updateToken = true
            const time = localStorage.getItem("touchDeviceTokenDate")
            if (time !== null) {
                const lstTimeTouched = parseInt(time)
                if (new Date().getTime() < lstTimeTouched + (24 * 60 * 60 * 1000)) {
                    updateToken = false
                }
            }

            if (updateToken) {
                const apiToken = Cookies.get(COOKIE.API_TOKEN)
                if (apiToken !== undefined && apiToken?.length > 0) {
                    touchDeviceToken(currentToken).then(({data}) => {
                        localStorage.setItem("touchDeviceTokenDate", new Date().getTime().toString())
                    })
                }
            }
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.')
            // ...
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err)
        // ...
    })
}