import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from "./utils/store";
import {Router} from "react-router-dom";
import {history} from './utils/router'
import {initTheme} from "./utils/theme";
import {I18nextProvider} from "react-i18next"
import i18next from "i18next"
import {initTranslation} from "./utils/i18n";
import {initUserSetting} from "./utils/userSettings";
import ScrollManager from "./utils/scroll-manager";

import {initCloudMessage} from "./utils/cloudMesseging";

initCloudMessage()
initTheme()
initTranslation()
initUserSetting()

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <I18nextProvider i18n={i18next}>
                {/*<ScrollManager />*/}
                <App />
            </I18nextProvider>
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
