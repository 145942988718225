import {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {useTranslation} from "react-i18next"
import classNames from "classnames"
import i18n from "i18next"

interface Props {
    show: boolean,
    onClose: any
}

function SelectLanguageModal({show, onClose}: Props) {

    const {t} = useTranslation('main', { keyPrefix: 'landing' })

    const setLanguage = (locale: string): void => {
        i18n.changeLanguage(locale)
        onClose()
    }

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-y-auto" style={{zIndex: 100}} onClose={onClose}>
                <div className="flex items-center min-h-screen justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            {t('selectLanguage.select')}
                                        </Dialog.Title>
                                        <hr className={"my-3"}/>
                                        <div className={"pt-2"}>
                                            <div className={"grid grid-cols-3 gap-4 place-items-left"}>
                                                <div className={"text-left"}>
                                                    <button
                                                        onClick={() => setLanguage('en')}
                                                        className={classNames("text-base transition duration-150 border-b-2 py-0 border-transparent hover:border-black", i18n.language === 'en' ? "font-bold" : '')}>
                                                        {'English'}
                                                    </button>
                                                </div>
                                                <div className={"text-left"}>
                                                    <button
                                                        onClick={() => setLanguage('ru')}
                                                        className={classNames("text-base transition duration-150 border-b-2 py-0 border-transparent hover:border-black", i18n.language === 'ru' ? "font-bold" : '')}>
                                                        {'Русский'}
                                                    </button>
                                                </div>
                                                <div className={"text-left"}>
                                                    <button
                                                        onClick={() => setLanguage('uk')}
                                                        className={classNames("text-base transition duration-150 border-b-2 py-0 border-transparent hover:border-black", i18n.language === 'uk' ? "font-bold" : '')}>
                                                        {'Українська'}
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={onClose}
                                >
                                    {t('selectLanguage.close')}
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )

}

export default SelectLanguageModal