import {Reducer} from 'redux'
import {produce} from 'immer'
import {ContinueWhereLeftActionTypes, ContinueWhereLeftState} from './types'

const initialContinueWhereLeftState: ContinueWhereLeftState = {
    loading: false,
    studies: []
}

const reducer: Reducer<ContinueWhereLeftState> = (state = initialContinueWhereLeftState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case ContinueWhereLeftActionTypes.SET_CONTINUE_WHERE_LEFT_LOADING:
                draft.loading = action.payload
                break
            case ContinueWhereLeftActionTypes.SET_CONTINUE_WHERE_LEFT:
                draft.studies = action.payload
                break
            default:
                break
        }
    })
}

export { reducer as ContinueWhereLeftReducer }
