import {combineReducers, createStore, Store} from 'redux'
// import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import {AuthState} from './auth/types'
import {AuthReducer} from "./auth/reducers"
import {PracticeState} from "./practice/types";
import {PracticeReducer} from "./practice/reducers"
import {enableMapSet} from "immer"
import {NotificationsState} from "./notifications/types";
import {NotificationsReducer} from "./notifications/reducers";
import {ContinueWhereLeftState} from "./continueWhereLeft/types";
import {ContinueWhereLeftReducer} from "./continueWhereLeft/reducers";

export interface State {
    auth: AuthState
    practice: PracticeState
    notifications: NotificationsState
    continueWhereLeft: ContinueWhereLeftState
}

// function* rootSaga() {
//     yield all([fork(authSaga)])
// }

export default function configureStore(state?: Partial<State>): Store<State> {
    // const sagaMiddleware = createSagaMiddleware()
    enableMapSet()

    const store = createStore(
        combineReducers({
            auth: AuthReducer,
            practice: PracticeReducer,
            notifications: NotificationsReducer,
            continueWhereLeft: ContinueWhereLeftReducer
        }),
        state,
        // applyMiddleware(sagaMiddleware)
    )

    // sagaMiddleware.run(rootSaga)

    return store
}