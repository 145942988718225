import {Link} from "react-router-dom"
import {useTranslation} from "react-i18next"
import SelectLanguageModal from "../../components/Landing/SelectLanguageModal"
import {useEffect, useState} from "react"
import {displayLanguage} from "../../utils/language"
import i18n from "i18next"
import classNames from "classnames"
import LogoMultilio from "../../icons/logoMultilio";
import {ROUTES} from "../../constants/routes";
import { LazyLoadImage } from "react-lazy-load-image-component"
import 'react-lazy-load-image-component/src/effects/blur.css'
import {cdnHost} from "../../constants/api";

function Landing3() {

    const {t} = useTranslation('main', { keyPrefix: 'landing' })

    const [selectLanguageModal, setSelectLanguageModal] = useState<boolean>(false)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const cdn: string = cdnHost()

    useEffect(() => {
        document.title = 'Learn English words | Multilio'
    }, [])

    useEffect(() => {
        const utm_source = new URLSearchParams(window.location.search).get("utm_source")
        if (utm_source !== null) window.localStorage.setItem("cId", utm_source)
    }, [])

    if (showMenu) {
        return (
            <div style={{backgroundColor: '#FFFC00'}} className={"min-h-screen "}>
                <SelectLanguageModal onClose={()=>{setSelectLanguageModal(false)}} show={selectLanguageModal} />
                <div className={"fixed top-0 w-full backdrop-blur-sm"}>
                    <div>

                        <div className={"grid grid-flow-col grid-rows-1 grid-cols-12 gap-4 py-2 items-center"}>
                            <div className={"col-span-2 col-start-2"}>
                                {/*<LazyLoadImage*/}
                                {/*    id={'landingMainImage'}*/}
                                {/*    className={'object-contain'}*/}
                                {/*    style={{height: '50px'}}*/}
                                {/*    src={'/logo/logo-light-v3.png'} />*/}
                            </div>

                            <div className={"col-span-7 col-start-5 text-right"}>
                                <div className="inline-block align-middle ml-8">
                                    <button
                                        onClick={()=>setShowMenu(!showMenu)}
                                        type="button"
                                        className="inline-flex justify-center rounded-full px-2 py-2"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                             className={classNames("w-6 h-6", showMenu ? 'hidden' : 'inline-block')}
                                             style={{verticalAlign: 'middle'}}>
                                            <path d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"/>
                                        </svg>

                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                                             className={classNames("w-6 h-6", showMenu ? 'inline-block' : 'hidden')}
                                             style={{verticalAlign: 'middle'}}>
                                            <path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"pt-20"}>
                    <div style={{backgroundColor: '#FFFC00'}} className="grid grid-cols-1 gap-4">
                        <div className={"px-4 text-center"}>

                            <div className="my-4 divide-y divide-gray-900">
                                <div>
                                    {/*<div className={"my-4"}>*/}
                                    {/*    <button className="text-xl font-bold transition duration-150 border-b-2 py-0 border-transparent hover:border-black">*/}
                                    {/*        {t('features')}*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                    <div className={"my-4"}>
                                        <Link to={ROUTES.POLICY.TERMS}>
                                            <button className="text-xl font-bold transition duration-150 border-b-2 py-0 border-transparent hover:border-black">
                                                {t('terms')}
                                            </button>
                                        </Link>
                                    </div>
                                    <div className={"my-4"}>
                                        <Link to={ROUTES.POLICY.PRIVACY}>
                                            <button className="text-xl font-bold transition duration-150 border-b-2 py-0 border-transparent hover:border-black">
                                                {t('privacy')}
                                            </button>
                                        </Link>
                                    </div>
                                    <div className={"my-4"}>
                                        <a href={'mailto:justspeakai@gmail.com'}>
                                            <button className="text-xl font-bold transition duration-150 border-b-2 py-0 border-transparent hover:border-black">
                                                {t('contact')}
                                            </button>
                                        </a>
                                    </div>
                                    <div className={"my-4"}>
                                        <button
                                            onClick={() => setSelectLanguageModal(true)}
                                            className="text-xl font-bold transition duration-150 border-b-2 py-0 border-transparent hover:border-black">
                                            {t('language') + ': ' + displayLanguage(i18n.language)}
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <>
            <SelectLanguageModal onClose={()=>{setSelectLanguageModal(false)}} show={selectLanguageModal} />

            <div className="flex flex-col h-screen">
                <div className={"fixed w-full backdrop-blur-sm"}>
                    <div className={"grid grid-flow-col grid-rows-1 grid-cols-12 gap-4 py-2 items-center"}>
                        {/*<div className={"col-span-3 col-start-2"}>*/}
                        {/*    <LogoMultilio classNames={"w-24"} dark={true} />*/}
                        {/*</div>*/}

                        <div className={"col-span-7 col-start-5 text-right md:hidden lg:hidden xl:hidden inline"}>
                            <div className="inline-block align-middle ml-8">
                                <button
                                    onClick={()=>setShowMenu(!showMenu)}
                                    type="button"
                                    className="inline-flex justify-center rounded-full px-2 py-2"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                         className={classNames("w-6 h-6", showMenu ? 'hidden' : 'inline-block')}
                                         style={{verticalAlign: 'middle'}}>
                                        <path d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"/>
                                    </svg>

                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                                         className={classNames("w-6 h-6", showMenu ? 'inline-block' : 'hidden')}
                                         style={{verticalAlign: 'middle'}}>
                                        <path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    // style={{backgroundColor: '#FFFC00'}}
                     className="flex-grow xl:pt-20 lg:pt-20 md:pt-20 pt-20 pb-10">
                    <div>


                        <div className="flex w-full justify-center items-center mt-20 pb-20">
                            <LazyLoadImage
                                id={'landingMainImage'}
                                className={'object-contain'}
                                style={{height: '60px'}}
                                src={'/logo/logo-light-v3.png'} />
                        </div>

                        <h1 className={"px-5 text-6xl md:text-7xl lg:text-7xl xl:text-8xl font-medium text-center text-blue-600"}>
                            {t('title')}
                        </h1>
                        <div className={"grid grid-cols-12"}>
                            <div className={"xl:col-start-4 xl:col-span-6 col-span-12"}>
                                <h3 className={"text-gray-500 px-5 text-lg md:2text-xl lg:text-2xl xl:text-2xl xl:pt-10 lg:pt-10 pt-5 font-semibold text-center"}>
                                    {t('subTitle')}
                                </h3>
                            </div>
                        </div>


                        <div className="flex w-full justify-center items-center mt-20 pb-10">
                            <a href={"https://apps.apple.com/us/app/justspeak-learn-english/id6449247049"}>
                                <LazyLoadImage
                                    className={'object-contain'}
                                    style={{height: '100px'}}
                                    src={'https://www.talkatone.com/wp-content/themes/talkatone/img/get-app-app-store.png'} />
                            </a>
                        </div>

                    </div>
                </div>
                <div>
                    <footer id="landingFooter" className={"w-full pb-10 xl:inline lg:inline md:inline hidden"}>
                        <div className={"md:pt-20 grid grid-flow-col md:grid-rows-1 lg:grid-rows-1 xl:grid-rows-1 grid-rows-4 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 grid-cols-1 gap-4 py-4 place-items-center"}>
                            <div className={"xl:col-start-3 xl:col-span-2 lg:col-start-3 lg:col-span-2 md:col-start-1 md:col-span-3 text-center"}>
                                <Link to={ROUTES.POLICY.TERMS}>
                                    <button className="text-base font-bold transition duration-150 border-b-2 py-0 border-transparent hover:border-black">
                                        {t('terms')}
                                    </button>
                                </Link>
                            </div>
                            <div className={"xl:col-start-5 xl:col-span-2 lg:col-start-5 lg:col-span-2 md:col-start-4 md:col-span-3 text-center"}>
                                <Link to={ROUTES.POLICY.PRIVACY}>
                                    <button className="text-base font-bold transition duration-150 border-b-2 py-0 border-transparent hover:border-black">
                                        {t('privacy')}
                                    </button>
                                </Link>
                            </div>
                            <div className={"xl:col-start-7 xl:col-span-2 lg:col-start-7 lg:col-span-2 md:col-start-7 md:col-span-3 text-center"}>
                                <a href={'mailto:justspeakai@gmail.com'}>
                                    <button className="text-base font-bold transition duration-150 border-b-2 py-0 border-transparent hover:border-black">
                                        {t('contact')}
                                    </button>
                                </a>
                            </div>
                            <div className={"xl:col-start-9 xl:col-span-2 lg:col-start-9 lg:col-span-2 md:col-start-10 md:col-span-3 text-center"}>
                                <button
                                    onClick={() => setSelectLanguageModal(true)}
                                    className="text-base font-bold transition duration-150 border-b-2 py-0 border-transparent hover:border-black">
                                    {t('language') + ': ' + displayLanguage(i18n.language)}
                                </button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>

        </>
    )
}

export default Landing3