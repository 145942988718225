import {Reducer} from 'redux'
import {produce} from 'immer'
import {NotificationActionTypes, NotificationsState} from './types'

const initialNotificationsState: NotificationsState = {
    newNotificationsCount: 0
}

const reducer: Reducer<NotificationsState> = (state = initialNotificationsState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case NotificationActionTypes.SET_NEW_NOTIFICATIONS_COUNT:
                draft.newNotificationsCount = action.payload
                break
            default:
                break
        }
    })
}

export { reducer as NotificationsReducer }
