import {StudyBasicInfo} from "../../interfaces/summary";

export enum ContinueWhereLeftActionTypes {
    SET_CONTINUE_WHERE_LEFT_LOADING = '@continueWhereLeft/SET_CONTINUE_WHERE_LEFT_LOADING',
    SET_CONTINUE_WHERE_LEFT = '@continueWhereLeft/SET_CONTINUE_WHERE_LEFT',

}

export interface ContinueWhereLeftState {
    loading: boolean
    studies: StudyBasicInfo[]
}