import {Reducer} from 'redux'
import {produce} from 'immer'
import {PracticeActionTypes, PracticeState} from './types'

let initialPracticeState: PracticeState = {
    words: [],
    extrasWords: [],
    wordIndex: 0,
    stepsCount: 0,
    stepIndex: 0,
    startedAt: 0,
    finishedAt: 0,
    practiceDefinitionStatistic: [],
    practiceSelectDefinitionStatistic: [],
    practiceListeningStatistic: [],
    practiceMissingLettersStatistic: [],
    practiceCollectWordStatistic: [],
    translation: { autoTranslate: false, translateTo: '' },
    skippedWords: [],
    skippedSenses: [],
    sensesToStudy: []
}

let practiceData = window.localStorage.getItem('practiceData')
if (practiceData !== null && practiceData.length > 0) {
    initialPracticeState = JSON.parse(practiceData)
    practiceData = null
}

const reducer: Reducer<PracticeState> = (state = initialPracticeState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case PracticeActionTypes.PRACTICE_RESET:
                draft.words = []
                draft.extrasWords = []
                draft.wordIndex = 0
                draft.stepsCount = 0
                draft.stepIndex = 0
                draft.startedAt = 0
                draft.finishedAt = 0
                draft.practiceDefinitionStatistic = []
                draft.practiceSelectDefinitionStatistic = []
                draft.practiceListeningStatistic = []
                draft.practiceMissingLettersStatistic = []
                draft.practiceCollectWordStatistic = []
                draft.skippedWords = []
                draft.skippedSenses = []
                draft.sensesToStudy = []
                break
            case PracticeActionTypes.SET_PRACTICE_WORDS:
                draft.words = action.payload
                break
            case PracticeActionTypes.SET_PRACTICE_TRANSLATION:
                draft.translation = action.payload
                break
            case PracticeActionTypes.SET_PRACTICE_EXTRAS_WORDS:
                draft.extrasWords = action.payload
                break
            case PracticeActionTypes.SET_PRACTICE_STUDY_TYPE:
                draft.studyType = action.payload
                break
            case PracticeActionTypes.SET_CURRENT_PRACTICE_WORD_INDEX:
                draft.wordIndex = action.payload
                break
            case PracticeActionTypes.SET_PRACTICE:
                draft = action.payload
                break
            case PracticeActionTypes.SET_PRACTICE_AUTOPLAY:
                draft.autoplay = action.payload
                break
            case PracticeActionTypes.SET_PRACTICE_STEP_INDEX:
                draft.stepIndex = action.payload
                break
            case PracticeActionTypes.SET_PRACTICE_STEPS_COUNT:
                draft.stepsCount = action.payload
                break
            case PracticeActionTypes.SET_PRACTICE_STARTED_AT:
                draft.startedAt = action.payload
                break
            case PracticeActionTypes.SET_PRACTICE_FINISHED_AT:
                draft.finishedAt = action.payload
                break
            case PracticeActionTypes.ADD_PRACTICE_DEFINITION_STATISTIC:
                let _practiceDefinitionStatistic = draft.practiceDefinitionStatistic
                _practiceDefinitionStatistic.push(action.payload)
                draft.practiceDefinitionStatistic = _practiceDefinitionStatistic
                break
            case PracticeActionTypes.ADD_PRACTICE_SELECT_DEFINITION_STATISTIC:
                let _practiceSelectDefinitionStatistic = draft.practiceSelectDefinitionStatistic
                _practiceSelectDefinitionStatistic.push(action.payload)
                draft.practiceSelectDefinitionStatistic = _practiceSelectDefinitionStatistic
                break
            case PracticeActionTypes.ADD_PRACTICE_LISTENING_STATISTIC:
                let _practiceListeningStatistic = draft.practiceListeningStatistic
                _practiceListeningStatistic.push(action.payload)
                draft.practiceListeningStatistic = _practiceListeningStatistic
                break
            case PracticeActionTypes.ADD_PRACTICE_MISTING_LETTER_STATISTIC:
                let _practiceMissingLettersStatistic = draft.practiceMissingLettersStatistic
                _practiceMissingLettersStatistic.push(action.payload)
                draft.practiceMissingLettersStatistic = _practiceMissingLettersStatistic
                break
            case PracticeActionTypes.ADD_PRACTICE_COLLECT_WORD_STATISTIC:
                let _practiceCollectWordStatistic = draft.practiceCollectWordStatistic
                _practiceCollectWordStatistic.push(action.payload)
                draft.practiceCollectWordStatistic = _practiceCollectWordStatistic
                break
            case PracticeActionTypes.SET_PRACTICE_SKIPPED_SENSES:
                let _skippedSenses = draft.skippedSenses
                _skippedSenses = _skippedSenses.concat(action.payload)
                draft.skippedSenses = _skippedSenses
                break
            case PracticeActionTypes.SET_PRACTICE_SKIPPED_WORDS:
                let _skippedWords = draft.skippedWords
                _skippedWords.push(action.payload)
                draft.skippedWords = _skippedWords
                break
            case PracticeActionTypes.SET_PRACTICE_SENSES_TO_STUDY:
                let _sensesToStudy = draft.sensesToStudy
                _sensesToStudy = _sensesToStudy.concat(action.payload)
                draft.sensesToStudy = _sensesToStudy
                break
            default:
                break
        }
        window.localStorage.setItem('practiceData', JSON.stringify(draft))
    })
}

export { reducer as PracticeReducer }
