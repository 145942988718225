import {settingsLanguage} from "../api/settings";
import i18n from "i18next";
import Cookies from "js-cookie";
import {COOKIE} from "../constants/api";

export function initUserSetting() {
    const apiToken = Cookies.get(COOKIE.API_TOKEN)
    if (apiToken === undefined || apiToken?.length === 0)
        return false
    settingsLanguage().then(({data}) => {
        if (i18n.language !== data.locale) {
            i18n.changeLanguage(data.locale).then((ignore) => {})
        }
    })
}