import React, {useEffect, useState} from "react"
import TextureLoader from "./TextureLoader";
import LogoMultilio from "../../icons/logoMultilio";

const InitializingLoader = () => {

    const [progress, setProgress] = useState<number>(0)

    useEffect(() => {
        const loadingProgress = setInterval(()=>{
            if (progress < 50) {
                setProgress(progress + 5)
            } else if (progress < 75) {
                setProgress(progress + 2)
            } else if (progress < 90) {
                setProgress(progress + 1)
            } else if (progress < 98) {
                setProgress(progress + 1)
            }
            clearInterval(loadingProgress)
        }, 50)

        return () => clearInterval(loadingProgress)
    }, [])

    return (
        <>
            <div className={"fixed top-0 w-full"}>
                <div className="overflow-hidden h-1 text-xs flex bg-indigo-300 dark:bg-purple-300">
                    <div style={{width: progress + '%'}}
                         className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500 dark:bg-purple-500"/>
                </div>
            </div>
            <div className="h-screen grid grid-cols-1 gap-4 content-center bg-gray-100 dark:bg-gray-900">
                <div className={"px-4 items-center"}>
                    <div className={"flex justify-center"}>
                        {/*<LogoMultilio classNames={"xl:w-72 lg:w-48 md:w-48 w-36"} />*/}
                    </div>
                    {/*<h1 className={"text-3xl md:text-6xl lg:text-7xl xl:text-8xl font-base text-center text-gray-600 dark:text-gray-300"}>*/}
                    {/*    multilio*/}
                    {/*</h1>*/}
                </div>
            </div>
        </>
    )
}

export default InitializingLoader