export const codeToLanguage = (code: string | undefined): string | undefined => {
    if (code === undefined) return code
    switch (code) {
        case 'af': return 'Afrikaans (Afrikaans)'
        case 'in': return 'Bahasa Indonesia (Bahasa Indonesia)'
        case 'ms': return 'Bahasa Malaysia (Malay)'
        case 'bs': return 'Bosanski (Bosnian)'
        case 'ca': return 'Català (Catalan)'
        case 'cs': return 'Čeština (Czech)'
        case 'cy': return 'Cymraeg (Welsh)'
        case 'da': return 'Dansk (Danish)'
        case 'de': return 'Deutsch (German)'
        case 'et': return 'Eesti keel (Estonian)'
        case 'en': return 'English (English)'
        case 'es': return 'Español (Spanish)'
        case 'fr': return 'Français (French)'
        case 'hr': return 'Hrvatski (Croatian)'
        case 'it': return 'Italiano (Italian)'
        case 'sw': return 'Kiswahili (Swahili)'
        case 'lv': return 'Latviešu valoda (Latvian)'
        case 'lt': return 'Lietuvių kalba (Lithuanian)'
        case 'hu': return 'Magyar (Hungarian)'
        case 'mt': return 'Malti (Maltese)'
        case 'nl': return 'Nederlands (Dutch)'
        case 'no': return 'Norsk (Norwegian)'
        case 'pl': return 'Polski (Polish)'
        case 'pt': return 'Português (Portuguese)'
        case 'ro': return 'Română (Romanian)'
        case 'sk': return 'Slovenčina (Slovak)'
        case 'sl': return 'Slovenščina (Slovenian)'
        case 'sr': return 'Cрпски (Serbian)'
        case 'fi': return 'Suomi (Finnish)'
        case 'sv': return 'Svenska (Swedish)'
        case 'tl': return 'Tagalog (Tagalog)'
        case 'vi': return 'Tiếng việt (Vietnamese)'
        case 'tr': return 'Türkçe (Turkish)'
        case 'zh': return '简体中文 (Chinese)'
        case 'ja': return '日本語 (Japanese)'
        case 'ko': return '한국어 (Korean)'
        case 'ar': return 'العربية (Arabic)'
        case 'fa': return 'فارسى (Farsi)'
        case 'he': return 'עברית (Hebrew)'
        case 'ur': return 'اردو (Urdu)'
        case 'hi': return 'हिन्दी (Hindi)'
        case 'th': return 'ภาษาไทย (Thai)'
        case 'el': return 'ελληνικά (Greek)'
        case 'bg': return 'български (Bulgarian)'
        case 'ru': return 'Русский (Russian)'
        case 'uk': return 'Українська (Ukrainian)'
    }
    return undefined
}

export const codeToLanguageWithoutCountryCode = (code: string | undefined): string | undefined => {
    if (code === undefined) return code
    switch (code) {
        case 'af': return 'Afrikaans (Afrikaans)'
        case 'in': return 'Bahasa Indonesia (Bahasa Indonesia)'
        case 'ms': return 'Bahasa Malaysia (Malay)'
        case 'bs': return 'Bosanski (Bosnian)'
        case 'ca': return 'Català (Catalan)'
        case 'cs': return 'Čeština (Czech)'
        case 'cy': return 'Cymraeg (Welsh)'
        case 'da': return 'Dansk (Danish)'
        case 'de': return 'Deutsch (German)'
        case 'et': return 'Eesti keel (Estonian)'
        case 'en': return 'English'
        case 'es': return 'Español (Spanish)'
        case 'fr': return 'Français (French)'
        case 'hr': return 'Hrvatski (Croatian)'
        case 'it': return 'Italiano (Italian)'
        case 'sw': return 'Kiswahili (Swahili)'
        case 'lv': return 'Latviešu valoda (Latvian)'
        case 'lt': return 'Lietuvių kalba (Lithuanian)'
        case 'hu': return 'Magyar (Hungarian)'
        case 'mt': return 'Malti (Maltese)'
        case 'nl': return 'Nederlands (Dutch)'
        case 'no': return 'Norsk (Norwegian)'
        case 'pl': return 'Polski (Polish)'
        case 'pt': return 'Português (Portuguese)'
        case 'ro': return 'Română (Romanian)'
        case 'sk': return 'Slovenčina (Slovak)'
        case 'sl': return 'Slovenščina (Slovenian)'
        case 'sr': return 'Cрпски (Serbian)'
        case 'fi': return 'Suomi (Finnish)'
        case 'sv': return 'Svenska (Swedish)'
        case 'tl': return 'Tagalog (Tagalog)'
        case 'vi': return 'Tiếng việt (Vietnamese)'
        case 'tr': return 'Türkçe (Turkish)'
        case 'zh': return '正體中文 (Chinese)'
        case 'ja': return '日本語 (Japanese)'
        case 'ko': return '한국어 (Korean)'
        case 'ar': return 'العربية (Arabic)'
        case 'fa': return 'فارسى (Farsi)'
        case 'he': return 'עברית (Hebrew)'
        case 'ur': return 'اردو (Urdu)'
        case 'hi': return 'हिन्दी (Hindi)'
        case 'th': return 'ภาษาไทย (Thai)'
        case 'el': return 'ελληνικά (Greek)'
        case 'bg': return 'български (Bulgarian)'
        case 'ru': return 'Русский (Russian)'
        case 'uk': return 'Українська (Ukrainian)'
    }
    return undefined
}

export const displayLanguage = (code: string | undefined): string => {
    if (code === undefined) return 'English'
    switch (code) {
        case 'en': return 'English'
        case 'ru': return 'Русский'
        case 'uk': return 'Українська'
        default: return 'English'
    }
}

export const formatLanguageCode = (code: string | undefined): string => {
    if (code === undefined) return 'en'
    if (code.indexOf('-') > -1) {
        code = code.split('-')[0]
    } else if (code.indexOf('_') > -1) {
        code = code.split('_')[0]
    }
    switch (code) {
        case 'en':
        case 'ru':
        case 'uk': return code
        default: return 'en'
    }
}